// Blank L00K Template





// CSS
import '../styles/l00k__blank.scss';


// JS


// Svg
// https://parceljs.org/languages/svg/#inlining-as-a-string




// Thing
import arpSounds from './components/breathing';


document.addEventListener('DOMContentLoaded', function () {

	
	// L00K NAV
	var toggleLookNav = document.querySelectorAll('.toggle__l00k__nav');
	const mainLook = document.querySelectorAll('.l00k');

	toggleLookNav.forEach(function (toggleButton) {
		if (toggleButton instanceof HTMLButtonElement) {

			toggleButton.onclick = function (event) {

				mainLook.forEach(look => look.classList.toggle("w__l00k__nav"));

				if (toggleButton.getAttribute("aria-expanded") == "true") {
					toggleButton.setAttribute("aria-expanded", "false");
				} else {
					toggleButton.setAttribute("aria-expanded", "true");
				};

			};
		}
	});

    // Add toggle group functionality
    const toggleGroup = document.querySelector('.toggle-group');
    if (toggleGroup) {
        const toggleButtons = toggleGroup.querySelectorAll('button');
        
        toggleButtons.forEach(button => {
            button.addEventListener('click', () => {
                // Remove active state from all buttons
                toggleButtons.forEach(btn => {
                    btn.setAttribute('aria-pressed', 'false');
                    btn.classList.remove('active');
                });
                
                // Set active state for clicked button
                button.setAttribute('aria-pressed', 'true');
                button.classList.add('active');
                
                // Remove all mode classes from body
                document.body.classList.remove('__b', '__c', '__d');
                
                // Add the new mode class
                const mode = button.getAttribute('data-mode');
                if (mode) {
                    document.body.classList.add(mode);
                }
            });
        });
    }
    
}, false);