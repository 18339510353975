import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import * as Tone from 'tone';
import '../../styles/components/_breathing.scss';
import Draggable from 'gsap/Draggable';

// Register the Draggable plugin
gsap.registerPlugin(Draggable);

document.addEventListener('DOMContentLoaded', function () {
    console.log('DOM Content Loaded'); // Verify our code is running

    gsap.registerPlugin(CustomEase, MorphSVGPlugin);

    gsap.set('.breathDot', { scale: 1 });
    console.log('Start');

    // Initialize color picker buttons
    const foregroundBtn = document.getElementById('foregroundColorPicker');
    const backgroundBtn = document.getElementById('backgroundColorPicker');
    
    console.log('Found buttons:', { foregroundBtn, backgroundBtn }); // Check if buttons exist
    
    // Get initial colors from CSS variables
    const root = document.documentElement;
    const getInitialColor = (varName) => getComputedStyle(root).getPropertyValue(varName).trim();
    
    // Function to update meta theme colors
    function updateMetaThemeColors() {
        // Get the current mode from body classes
        const body = document.body;
        let backgroundColor;

        if (body.classList.contains('__b')) {
            backgroundColor = 'hsla(0, 100%, 54%, 1)';
        } else if (body.classList.contains('__c')) {
            backgroundColor = 'hsla(30, 70%, 15%, 1)';
        } else if (body.classList.contains('__d')) {
            backgroundColor = 'hsla(0, 0%, 20%, 1)';
        } else {
            // Default mode
            backgroundColor = 'hsla(300, 100%, 100%, 1)';
        }

        console.log('Updating meta theme colors to:', backgroundColor);
        
        // Update meta tags
        let themeColorMeta = document.querySelector('meta[name="theme-color"]');
        if (!themeColorMeta) {
            themeColorMeta = document.createElement('meta');
            themeColorMeta.name = 'theme-color';
            document.head.appendChild(themeColorMeta);
        }
        themeColorMeta.setAttribute('content', backgroundColor);
        
        let tileMeta = document.querySelector('meta[name="msapplication-TileColor"]');
        if (!tileMeta) {
            tileMeta = document.createElement('meta');
            tileMeta.name = 'msapplication-TileColor';
            document.head.appendChild(tileMeta);
        }
        tileMeta.setAttribute('content', backgroundColor);
        
        let navMeta = document.querySelector('meta[name="msapplication-navbutton-color"]');
        if (!navMeta) {
            navMeta = document.createElement('meta');
            navMeta.name = 'msapplication-navbutton-color';
            document.head.appendChild(navMeta);
        }
        navMeta.setAttribute('content', backgroundColor);
    }

    // Set initial button colors and text
    if (foregroundBtn && backgroundBtn) {
        console.log('Setting up color pickers');
        
        const initialForeground = getInitialColor('--color-foreground') || '#000000';
        const initialBackground = getInitialColor('--color-background') || '#ffffff';
        
        foregroundBtn.style.backgroundColor = initialForeground;
        backgroundBtn.style.backgroundColor = initialBackground;
        foregroundBtn.textContent = initialForeground;
        backgroundBtn.textContent = initialBackground;
        
        // Add click handlers for color pickers
        foregroundBtn.addEventListener('click', () => {
            const picker = document.createElement('input');
            picker.type = 'color';
            picker.value = getInitialColor('--color-foreground') || '#000000';
            
            picker.addEventListener('input', (e) => {
                const newColor = e.target.value;
                root.style.setProperty('--color-foreground', newColor);
                foregroundBtn.style.backgroundColor = newColor;
                foregroundBtn.textContent = newColor;
            });
            
            picker.click();
        });
        
        backgroundBtn.addEventListener('click', () => {
            console.log('Background button clicked');
            const picker = document.createElement('input');
            picker.type = 'color';
            picker.value = getInitialColor('--color-background') || '#ffffff';
            
            picker.addEventListener('input', (e) => {
                const newColor = e.target.value;
                console.log('Color picker value changed to:', newColor);
                root.style.setProperty('--color-background', newColor);
                backgroundBtn.style.backgroundColor = newColor;
                backgroundBtn.textContent = newColor;
                updateMetaThemeColors();
            });
            
            picker.click();
        });
    }

    // Get elements for spawn animations
    const topSpawn = document.getElementById("respawn");
    const bottomSpawn = document.getElementById("respawnB");

    // Add breath duration variable and get slider
    let breathDuration = 4;
    const durationSlider = document.getElementById('breathDurationSlider');

    let colorTimeline;

    // Add Bluetooth-related variables
    let heartRateDevice = null;
    let heartRateCharacteristic = null;
    const HEART_RATE_SERVICE = 'heart_rate';
    const HEART_RATE_CHARACTERISTIC = 'heart_rate_measurement';

    // Add cadence-related variables
    let cadenceDevice = null;
    let cadenceCharacteristic = null;
    const CYCLING_SPEED_CADENCE_SERVICE = 'cycling_speed_and_cadence';
    const CSC_MEASUREMENT = 'csc_measurement';

    // Only show Bluetooth button for Chrome users
    const ua = navigator.userAgent.toLowerCase();
    const isChrome = ua.indexOf('chrome') !== -1;
    if (isChrome && 'bluetooth' in navigator) {
        document.getElementById('bluetooth-container').style.display = 'grid';
    }

    function breatheIn() {
        const tl = gsap.timeline();
        return tl.to('.breathDot', {
            duration: breathDuration,
            scale: 3,
            transformOrigin: 'center',
            ease: CustomEase.create("custom", "M0,0 C0.623,0.003 0.002,1.002 1,1 ")
        });
    }

    function breatheOut() {
        const tl = gsap.timeline();
        return tl.to('.breathDot', {
            duration: breathDuration,
            scale: 1,
            transformOrigin: 'center',
            ease: CustomEase.create("custom", "M0,0 C0.342,0.001 0.454,0.381 0.5,0.5 0.543,0.614 0.602,0.999 1,1 ")
        });
    }

    // Master breathing timeline
    const masterTimeline = gsap.timeline({
        repeat: -1,
        repeatDelay: 0
    });

    function updateMasterTimeline() {
        masterTimeline.clear();
        // Calculate pause durations - take 1s from end, add to start
        const startPause = 1;  // New 1s pause at start
        const endPause = breathDuration - 1;  // Reduce end pause by 1s
        
        masterTimeline
            .add(gsap.to({}, {duration: startPause}))  // Initial pause before breathing in
            .add(breatheIn())                          // Breathe in
            .add(breatheOut(), `+=${breathDuration}`)   // Hold, then breathe out
            .add(gsap.to({}, {duration: endPause}), ">"); // Shortened hold before next cycle

        // Create separate waves timeline that syncs with the last 4s of the breathing cycle
        // const wavesTimeline = gsap.timeline();
        // wavesTimeline
        //     .to('#waves', {
        //         duration: 1,
        //         rotation: 0,
        //         '--blur-amount': 0,
        //         ease: "none"
        //     })
        //     .to('#waves', {
        //         rotation: 0.25,
        //         '--blur-amount': 0.127,
        //         ease: "power1.inOut"
        //     })
        //     .to('#waves', {
        //         duration: breathDuration,
        //         rotation: 0.25,
        //         '--blur-amount': 0.127,
        //         ease: "power1.inOut"
        //     })
        //     .to('#waves', {
        //         duration: 0.125,
        //         rotation: -0.125,
        //         '--blur-amount': 0.125,
        //         ease: "power1.inOut"
        //     })
        //     .to('#waves', {
        //         duration: 0.125,
        //         rotation: 0,
        //         '--blur-amount': 0,
        //         ease: "power1.inOut"
        //     });

        // // Add waves timeline to start at the beginning of the last breathe out
        // masterTimeline.add(wavesTimeline, `-=${breathDuration * 4}`);
    }

    // Initial timeline setup
    updateMasterTimeline();

    // Add slider event listener
    if (durationSlider) {
        const durationDisplay = document.getElementById('durationDisplay');
        const breathingContainers = document.querySelectorAll('.breathing-container');
        const durationInput = document.querySelector('.breath-duration-input');

        // Only proceed if elements are found
        if (durationDisplay && breathingContainers.length && durationInput) {
            let isDragging = false;
            let isAnimating = false;

            // Set initial input value and prevent text selection
            durationInput.value = `${durationSlider.value}s`;
            durationInput.style.userSelect = 'none';
            
            // Add click handler to focus slider when input is clicked
            durationInput.addEventListener('click', (e) => {
                e.preventDefault();
                durationSlider.focus();
                
                // Find and animate the handle span
                const handleSpan = document.querySelector('.slider-handle-element span');
                if (handleSpan) {
                    handleSpan.classList.add('animate-focus');
                    setTimeout(() => {
                        handleSpan.classList.remove('animate-focus');
                    }, 400); // Match animation duration
                }
                
                // Trigger respawn animation at current duration
                if (!isDragging && !isAnimating) {
                    isAnimating = true;
                    gsap.to(breathingContainers, {
                        duration: 0.3,
                        opacity: 0,
                        onComplete: () => {
                            sliderRespawnAnimation();
                        }
                    });
                }
            });

            // Prevent any default input behavior
            durationInput.addEventListener('mousedown', (e) => {
                e.preventDefault();
            });

            class BreathSlider {
                constructor() {
                    this.slider = durationSlider;
                    this.handle = document.querySelector('.slider-handle-element');
                    this.sliderContainer = document.querySelector('.breath-duration-slider-container');
                    this.dragStarted = false;

                    // Initialize
                    this.setupEventListeners();
                    this.updateHandlePosition();
                }

                updateHandlePosition() {
                    if (!this.slider || !this.handle) return;

                    const percent = (this.slider.value - this.slider.min) / (this.slider.max - this.slider.min);
                    const containerHeight = this.sliderContainer.clientHeight;
                    const handleHeight = this.handle.offsetHeight;
                    const maxTranslate = containerHeight - handleHeight;
                    const translateY = maxTranslate * (1 - percent);
                    
                    this.handle.style.transform = `translate(-50%, ${translateY}px)`;
                }

                setupEventListeners() {
                    if (!this.slider || !this.sliderContainer) return;

                    // Create draggable instance with additional options
                    const draggable = Draggable.create(this.handle, {
                        type: "y",
                        bounds: this.sliderContainer,
                        inertia: false,
                        minimumMovement: 1,
                        allowContextMenu: true,
                        allowNativeTouchScrolling: false,
                        clickableTest: () => !this.dragStarted,
                        onPress: () => {
                            // Set a small delay to determine if it's a drag
                            setTimeout(() => {
                                this.dragStarted = true;
                            }, 100);
                        },
                        onDragStart: function() {
                            showDurationDisplay();
                        },
                        onDrag: function() {
                            const rect = this.target.parentElement.getBoundingClientRect();
                            const handleRect = this.target.getBoundingClientRect();
                            const handleCenter = handleRect.top + handleRect.height / 2;
                            const percent = (handleCenter - rect.top) / rect.height;
                            
                            const range = durationSlider.max - durationSlider.min;
                            const newValue = durationSlider.max - (percent * range);
                            
                            durationSlider.value = Math.min(durationSlider.max, Math.max(durationSlider.min, newValue));
                            durationSlider.dispatchEvent(new Event('input', { bubbles: true }));
                        },
                        onDragEnd: function() {
                            hideDurationDisplay();
                        },
                        onRelease: () => {
                            // Reset the drag state
                            this.dragStarted = false;
                        }
                    })[0];

                    // Force release on touch end
                    this.handle.addEventListener('touchend', () => {
                        if (draggable.isDragging) {
                            draggable.endDrag();
                            hideDurationDisplay();
                        }
                        this.dragStarted = false;
                    });

                    // Track click handler
                    this.sliderContainer.addEventListener('mousedown', (e) => {
                        if (e.target === this.handle) return;
                        
                        const rect = this.sliderContainer.getBoundingClientRect();
                        const clickY = e.clientY - rect.top;
                        const percent = clickY / rect.height;
                        
                        const range = this.slider.max - this.slider.min;
                        const newValue = this.slider.max - (percent * range);
                        
                        this.slider.value = Math.min(this.slider.max, Math.max(this.slider.min, newValue));
                        this.slider.dispatchEvent(new Event('input', { bubbles: true }));
                        this.updateHandlePosition();
                    });

                    this.slider.addEventListener('input', () => this.updateHandlePosition());
                    window.addEventListener('resize', () => this.updateHandlePosition());
                }
            }

            // Initialize the breath slider
            const breathSlider = new BreathSlider();

            function showDurationDisplay() {
                if (isAnimating) return;
                isDragging = true;
                masterTimeline.pause();
                gsap.to(breathingContainers, {
                    duration: 0.3,
                    opacity: 0
                });
                gsap.to(durationDisplay, {
                    duration: 0.3,
                    opacity: 1,
                    display: 'flex'
                });
                durationDisplay.innerHTML = `<span><span>${durationSlider.value}</span></span>`;
                durationInput.value = `${durationSlider.value}s`;
                breathSlider.updateHandlePosition();
            }

            function hideDurationDisplay() {
                isDragging = false;
                isAnimating = true; // Set animation flag
                gsap.to(durationDisplay, {
                    duration: 0.3,
                    opacity: 0,
                    display: 'none'
                });

                breathDuration = parseInt(durationSlider.value);
                masterTimeline.clear();
                updateMasterTimeline();
                gsap.set('.breathDot', { scale: 1 });

                gsap.delayedCall(0.3, () => {
                    sliderRespawnAnimation();
                    masterTimeline.restart(true);
                });
            }

            function handleClick(e) {
                if (!isDragging && !isAnimating) { // Check animation state
                    showDurationDisplay();
                    gsap.delayedCall(0.3, () => {
                        hideDurationDisplay();
                    });
                }
            }

            // Browser detection for slider
            const ua = navigator.userAgent.toLowerCase();
            const lookNav = document.querySelectorAll('.l00k__nav');
            const isSafari = (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
            const isChrome = ua.indexOf('chrome') !== -1;

            // Add quick reset animation function for Safari
            function quickResetAnimation() {
                const quickReset = gsap.timeline({
                    onComplete: () => {
                        startBreathing();
                        masterTimeline.play();
                    }
                });

                quickReset
                    .to('.breathDot', {
                        duration: 0.2,
                        scale: 0.8,
                        opacity: 0.5,
                        ease: "power2.in"
                    })
                    .to('.breathDot', {
                        duration: 0.3,
                        scale: 1,
                        opacity: 1,
                        ease: "power2.out"
                    });
            }

            // Apply browser-specific classes and behavior
            if (isSafari) {
                durationSlider.classList.add('safari-browser');
                lookNav.forEach(nav => nav.classList.add('safari-browser'));
                
                // Simplified behavior for Safari - no overlay
                durationSlider.addEventListener('input', function() {
                    durationInput.value = `${this.value}s`;
                    breathDuration = parseInt(this.value);
                    breathSlider.updateHandlePosition();
                    updateMasterTimeline();
                    quickResetAnimation(); // Add quick reset animation
                });
            } else {
                // Keep original behavior for other browsers
                durationSlider.addEventListener('mousedown', showDurationDisplay);
                durationSlider.addEventListener('mouseup', hideDurationDisplay);
                durationSlider.addEventListener('touchstart', showDurationDisplay);
                durationSlider.addEventListener('touchend', hideDurationDisplay);
                durationSlider.addEventListener('click', handleClick);
                
                durationSlider.addEventListener('input', function() {
                    if (!isDragging) {
                        showDurationDisplay();
                    }
                    durationDisplay.innerHTML = `<span><span>${this.value}</span></span>`;
                    durationInput.value = `${this.value}s`;
                    breathDuration = parseInt(this.value);
                    breathSlider.updateHandlePosition();
                });

                durationSlider.addEventListener('change', function() {
                    hideDurationDisplay();
                });
            }

            // Move sliderRespawnAnimation inside this scope
            function sliderRespawnAnimation() {
                const restartTimeline = gsap.timeline({
                    onComplete: () => {
                        startBreathing();
                        masterTimeline.play();
                        gsap.to(breathingContainers, {
                            duration: 0.3,
                            opacity: 1,
                            onComplete: () => {
                                gsap.delayedCall(0.1, () => {
                                    isAnimating = false;
                                });
                            }
                        });
                    }
                }).timeScale(1.5);

                restartTimeline
                    .add("launch")
                    // Top spawn collapse
                    .to(topSpawn, {
                        duration: 0.025,
                        opacity: 0,
                        ease: "slow(0.4, 0.4, false)"
                    }, "launch")
                    .to(bottomSpawn, {
                        duration: 0.025,
                        opacity: 0,
                        ease: "slow(0.4, 0.4, false)"
                    }, "launch")
                    .set([topSpawn, bottomSpawn], {
                        yPercent: (i) => i === 0 ? -300 : 300,
                        scaleX: "-=2",
                        scaleY: "+=4",
                        opacity: 1
                    }, "launch+=0.1")
                    .to([topSpawn, bottomSpawn], {
                        duration: 1,
                        yPercent: 0,
                        scaleX: 1,
                        scaleY: 1,
                        ease: "elastic.out(1, 0.8)"
                    }, "launch+=0.2");

                // Restart the master breathing timeline
                masterTimeline.restart();
            }
        }
    }

    // // Initialize color animation helper
    // function createColorTransition(fromColor, toColor) {
    //     colorTimeline = gsap.timeline({ repeat: -1 });
    //     return colorTimeline
    //         .to('article', {
    //             duration: 6,
    //             '--color-background': toColor,
    //             '--color-foreground': fromColor,
    //             ease: CustomEase.create("custom", "M0,0 C0,0 0.5,0 0.5,0.5 0.5,1 1,1.012 1,1 ")
    //         })
    //         .to('article', {
    //             duration: 6,
    //             '--color-background': fromColor,
    //             '--color-foreground': toColor,
    //             ease: CustomEase.create("custom", "M0,0 C0,0 0.5,0 0.5,0.5 0.5,1 1,1.012 1,1 ")
    //         });
    // }
    // createColorTransition('#000000', '#ffffff');


    // Initial launch animation
    const launchTimeline = gsap.timeline({
        onComplete: startBreathing
    });


    // Modify startBreathing to only start color animation if it's not already running
    function startBreathing() {


        // Ensure the master timeline is playing
        masterTimeline.play();
    }

    launchTimeline
        .add("launch", "+=1")
        .from(topSpawn, {
            duration: 0,
            opacity: 0
        })
        .from(bottomSpawn, {
            duration: 0,
            opacity: 0
        })
        .set([topSpawn, bottomSpawn], {
            yPercent: (i) => i === 0 ? -350 : 350,
            opacity: 0,
            scaleX: 0.1,
            scaleY: 4
        })
        .to([topSpawn, bottomSpawn], {
            duration: 0.75,
            yPercent: 0,
            scaleX: 1,
            scaleY: 1,
            opacity: 1,
            ease: "elastic.out(1, 0.8)"
        }, "launch");

    // Restart animation function
    function restartBreathing() {
        const restartTimeline = gsap.timeline({
            onComplete: startBreathing
        });

        restartTimeline
            .add("launch")
            // Top spawn collapse
            .to(topSpawn, {
                duration: 0.025,
                scaleY: 0.15,
                ease: "slow(0.4, 0.4, false)"
            }, "launch")
            .to(topSpawn, {
                duration: 0.075,
                scaleX: 0.5,
                ease: "slow(1, 0.4, false)"
            }, "launch+=0.05")
            .to(topSpawn, {
                duration: 0.05,
                scaleY: 0.25,
                ease: "slow(0.4, 0.4, false)"
            }, "launch+=0.05")
            .to(topSpawn, {
                duration: 0.075,
                scaleX: 0,
                ease: "slow(1, 0.4, false)"
            }, "launch+=0.05")
            .to(topSpawn, {
                duration: 0.025,
                scaleY: 0,
                opacity: 0,
                ease: "slow(0.4, 0.4, false)"
            }, "launch+=0.2")

            // Bottom spawn collapse
            .to(bottomSpawn, {
                duration: 0.025,
                scaleX: 0.15,
                ease: "slow(0.4, 0.4, false)"
            }, "launch+=0.05")
            .to(bottomSpawn, {
                duration: 0.075,
                scaleY: 0.5,
                ease: "slow(1, 0.4, false)"
            }, "launch+=0.1")
            .to(bottomSpawn, {
                duration: 0.05,
                scaleX: 0.25,
                ease: "slow(0.4, 0.4, false)"
            }, "launch+=0.1")
            .to(bottomSpawn, {
                duration: 0.075,
                scaleY: 0,
                ease: "slow(1, 0.4, false)"
            }, "launch+=0.1")
            .to(bottomSpawn, {
                duration: 0.025,
                scaleX: 0,
                opacity: 0,
                ease: "slow(0.4, 0.4, false)"
            }, "launch+=0.25")

            // Spawn back in
            .set([topSpawn, bottomSpawn], {
                yPercent: (i) => i === 0 ? -350 : 350,
                scaleX: "-=2",
                scaleY: "+=4",
                opacity: 1
            }, "launch+=1")
            .to([topSpawn, bottomSpawn], {
                duration: 1,
                yPercent: 0,
                scaleX: 1,
                scaleY: 1,
                ease: "elastic.out(1, 0.8)"
            }, "launch+=1.1");

        // Restart the master breathing timeline
        masterTimeline.restart();
    }

    // Add click handlers to restart animation
    const theEye = document.getElementById("theEye");
    const theEyeBlue = document.getElementById("theEyeBlue");
    const breathDots = document.querySelectorAll('.breathDot');

    if (theEye) {
        theEye.addEventListener('click', restartBreathing);
    }
    if (theEyeBlue) {
        theEyeBlue.addEventListener('click', restartBreathing);
    }
    // Add click handlers for breath dots
    breathDots.forEach(dot => {
        dot.addEventListener('click', async () => {
            await initTone(); // Ensure Tone.js is initialized
            audioConfig.pop.trigger(); // Trigger pop sound
            restartBreathing(); // Call existing animation
        });
    });

    // const wavesTimeline = gsap.timeline({
    //     repeat: -1
    // });

    // wavesTimeline
    //     .from('#waves', {
    //         duration: 14.6,
    //         rotate: 0,
    //         ease: "cubic.inOut"
    //     })

    //     .to('#waves', {
    //         duration: 14.7,
    //         rotate: -3,
    //         ease: "power1.inOut"
    //     })

    //     .to('#waves', {
    //         duration: 17.7,
    //         rotate: 2,
    //         ease: "power1.inOut"
    //     })
    //     .to('#waves', {
    //         duration: 14.6,
    //         rotate: 0,
    //         ease: "cubic.inOut"
    //     });

    // Update heart rate connection handler
    async function connectToHeartRateMonitor() {
        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: [{ services: [HEART_RATE_SERVICE] }]
            });

            const server = await device.gatt.connect();
            const service = await server.getPrimaryService(HEART_RATE_SERVICE);
            const characteristic = await service.getCharacteristic(HEART_RATE_CHARACTERISTIC);

            await characteristic.startNotifications();
            characteristic.addEventListener('characteristicvaluechanged', handleHeartRateChange);

            document.getElementById('heart-rate-display').style.display = 'block';
            document.getElementById('connect-bluetooth').style.display = 'none';
            
            // Update connection status
            const bluetoothContainer = document.getElementById('bluetooth-container');
            const bluetoothLabel = document.querySelector('#bluetooth-container label abbr');
            bluetoothContainer.classList.add('connected');
            if (bluetoothLabel) {
                bluetoothLabel.style.display = 'inline';
                bluetoothLabel.style.opacity = '1';
            }

            device.addEventListener('gattserverdisconnected', () => {
                document.getElementById('heart-rate-display').style.display = 'none';
                document.getElementById('connect-bluetooth').style.display = 'block';
                
                // Only update connection status if both devices are disconnected
                if (!cadenceDevice) {
                    const bluetoothContainer = document.getElementById('bluetooth-container');
                    const bluetoothLabel = document.querySelector('#bluetooth-container label abbr');
                    bluetoothContainer.classList.remove('connected');
                    if (bluetoothLabel) {
                        bluetoothLabel.style.opacity = '0';
                        bluetoothLabel.style.display = 'none';
                    }
                }
                
                heartRateDevice = null;
                heartRateCharacteristic = null;
            });

            heartRateDevice = device;
            heartRateCharacteristic = characteristic;

        } catch (error) {
            console.error('Bluetooth Error:', error);
            alert('Failed to connect to heart rate monitor. Please try again.');
        }
    }

    // Heart rate data handler
    function handleHeartRateChange(event) {
        const value = event.target.value;
        const heartRate = value.getUint8(1);
        document.getElementById('heart-rate-value').textContent = heartRate;
    }

    // Add event listener for Bluetooth button
    const connectButton = document.getElementById('connect-bluetooth');
    if (connectButton) {
        connectButton.addEventListener('click', connectToHeartRateMonitor);
    }

    // Add reconnection-related variables
    let lastKnownCadenceDevice = null;

    // Update the cadence connection handler
    async function connectToCadenceSensor() {
        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: [{ services: [CYCLING_SPEED_CADENCE_SERVICE] }]
            });

            lastKnownCadenceDevice = device;
            const server = await device.gatt.connect();
            const service = await server.getPrimaryService(CYCLING_SPEED_CADENCE_SERVICE);
            const characteristic = await service.getCharacteristic(CSC_MEASUREMENT);

            await characteristic.startNotifications();
            characteristic.addEventListener('characteristicvaluechanged', handleCadenceChange);

            document.getElementById('cadence-display').style.display = 'block';
            document.getElementById('connect-bluetooth-cad').style.display = 'none';
            
            // Update connection status
            const bluetoothContainer = document.getElementById('bluetooth-container');
            const bluetoothLabel = document.querySelector('#bluetooth-container label abbr');
            bluetoothContainer.classList.add('connected');
            if (bluetoothLabel) {
                bluetoothLabel.style.display = 'inline';
                bluetoothLabel.style.opacity = '1';
            }

            device.addEventListener('gattserverdisconnected', () => {
                document.getElementById('cadence-display').style.display = 'none';
                document.getElementById('connect-bluetooth-cad').style.display = 'block';
                
                // Only update connection status if both devices are disconnected
                if (!heartRateDevice) {
                    const bluetoothContainer = document.getElementById('bluetooth-container');
                    const bluetoothLabel = document.querySelector('#bluetooth-container label abbr');
                    bluetoothContainer.classList.remove('connected');
                    if (bluetoothLabel) {
                        bluetoothLabel.style.opacity = '0';
                        bluetoothLabel.style.display = 'none';
                    }
                }
                
                cadenceDevice = null;
                cadenceCharacteristic = null;
            });

            cadenceDevice = device;
            cadenceCharacteristic = characteristic;

        } catch (error) {
            console.error('Bluetooth Error:', error);
            alert('Failed to connect to cadence sensor. Please try again.');
        }
    }

    // Store previous measurements globally
    let lastCrankRevolutions;
    let lastCrankEventTime;

    // Cadence data handler
    function handleCadenceChange(event) {
        const value = event.target.value;
        const flags = value.getUint8(0);
        const hasCrankData = flags & 0x02;
        
        if (hasCrankData) {
            try {
                // Get current measurements from correct byte positions
                // Bytes: [flags(1), revolutions(2), time(2)]
                const crankRevolutions = value.getUint16(1, true); // Start at byte 1
                const lastEventTime = value.getUint16(3, true);    // Start at byte 3
                
                // Calculate cadence only if we have previous measurements
                if (lastCrankRevolutions !== undefined && lastCrankEventTime !== undefined) {
                    let timeDifference;
                    
                    // Handle timer overflow
                    if (lastEventTime < lastCrankEventTime) {
                        timeDifference = (65536 + lastEventTime - lastCrankEventTime) / 1024.0;
                    } else {
                        timeDifference = (lastEventTime - lastCrankEventTime) / 1024.0;
                    }
                    
                    // Handle revolution counter overflow
                    let revolutionDifference;
                    if (crankRevolutions < lastCrankRevolutions) {
                        revolutionDifference = (65536 + crankRevolutions - lastCrankRevolutions);
                    } else {
                        revolutionDifference = crankRevolutions - lastCrankRevolutions;
                    }
                    
                    // Calculate cadence if time difference is valid
                    if (timeDifference > 0) {
                        const cadence = Math.round((revolutionDifference / timeDifference) * 60);
                        
                        // Only update display if cadence is reasonable
                        if (cadence >= 0 && cadence <= 200) {
                            document.getElementById('cadence-value').textContent = cadence;
                        }
                    }
                }
                
                // Store current values for next calculation
                lastCrankRevolutions = crankRevolutions;
                lastCrankEventTime = lastEventTime;
                
            } catch (error) {
                console.error('Error parsing cadence data:', error);
            }
        }
    }

    // Add event listener for Cadence button
    const connectCadenceButton = document.getElementById('connect-bluetooth-cad');
    if (connectCadenceButton) {
        connectCadenceButton.addEventListener('click', connectToCadenceSensor);
    }

    // Audio configuration for two independent tracks
    const audioConfig = {
        track1: {
            synth: null,
            isInitialized: false,
            init() {
                if (this.isInitialized) return;
                
                const reverb = new Tone.Reverb({
                    decay: 5,
                    wet: 1,
                    preDelay: 0.1
                }).toDestination();

                // Create a gain node for smoother transitions
                const gainNode = new Tone.Gain(0).connect(reverb);
                
                const noise = new Tone.Noise({
                    type: "brown",
                    fadeIn: 0.2,
                    fadeOut: 0.2
                }).start();

                const noiseFilter = new Tone.Filter({
                    frequency: 432,
                    type: "bandpass",
                    Q: 8,
                    rolloff: -24
                }).connect(gainNode);
                
                noise.connect(noiseFilter);
                noise.volume.value = -25;

                this.synth = {
                    noise: noise,
                    filter: noiseFilter,
                    gain: gainNode,
                    volume: noise.volume
                };

                this.isInitialized = true;
            },
            start() {
                if (!this.isInitialized) this.init();
                // Use gain node for smoother transitions
                this.synth.gain.gain.cancelScheduledValues(Tone.now());
                this.synth.gain.gain.setValueAtTime(0, Tone.now());
                this.synth.gain.gain.exponentialRampToValueAtTime(1, Tone.now() + 0.025);
            },
            stop() {
                if (this.synth) {
                    // Smooth fadeout
                    this.synth.gain.gain.cancelScheduledValues(Tone.now());
                    this.synth.gain.gain.setValueAtTime(this.synth.gain.gain.value, Tone.now());
                    this.synth.gain.gain.exponentialRampToValueAtTime(0.001, Tone.now() + 0.5);
                }
            }
        },
        track2: {
            synth: null,
            isInitialized: false,
            init() {
                if (this.isInitialized) return;
                
                const reverb = new Tone.Reverb({
                    decay: 4,
                    wet: 1,
                    preDelay: 0.1
                }).toDestination();

                // Create a gain node for smoother transitions
                const gainNode = new Tone.Gain(0).connect(reverb);
                
                const noise = new Tone.Noise({
                    type: "pink",
                    fadeIn: 0.2,
                    fadeOut: 0.2
                }).start();

                const noiseFilter = new Tone.Filter({
                    frequency: 200,
                    type: "lowpass",
                    Q: 4,
                    rolloff: -24
                }).connect(gainNode);
                
                noise.connect(noiseFilter);
                noise.volume.value = -25;

                this.synth = {
                    noise: noise,
                    filter: noiseFilter,
                    gain: gainNode,
                    volume: noise.volume
                };

                this.isInitialized = true;
            },
            start() {
                if (!this.isInitialized) this.init();
                // Use gain node for smoother transitions
                this.synth.gain.gain.cancelScheduledValues(Tone.now());
                this.synth.gain.gain.setValueAtTime(0, Tone.now());
                this.synth.gain.gain.exponentialRampToValueAtTime(1, Tone.now() + 0.025);
            },
            stop() {
                if (this.synth) {
                    // Smooth fadeout
                    this.synth.gain.gain.cancelScheduledValues(Tone.now());
                    this.synth.gain.gain.setValueAtTime(this.synth.gain.gain.value, Tone.now());
                    this.synth.gain.gain.exponentialRampToValueAtTime(0.001, Tone.now() + 0.5);
                }
            }
        },
        pop: {
            synth: null,
            isInitialized: false,
            init() {
                if (this.isInitialized) return;
                
                const reverb = new Tone.Reverb({
                    decay: 11.5,
                    wet: 0.05
                }).toDestination();

                const synth = new Tone.Synth({
                    oscillator: {
                        type: "sine"
                    },
                    envelope: {
                        attack: 0.001,
                        decay: 22,
                        sustain: 1,
                        release: 0.1
                    }
                }).connect(reverb);

                synth.volume.value = -15;
                this.synth = synth;
                this.isInitialized = true;
            },
            trigger() {
                if (!this.isInitialized) this.init();
                
                // Play a quick note with pitch slide
                const now = Tone.now();
                this.synth.triggerAttackRelease("C2", "32n", now);
                this.synth.frequency.exponentialRampToValueAtTime("C3", now + 0.025);
            }
        }
    };

    let isToneInitialized = false;
    
    // Initialize Tone.js on first user interaction
    async function initTone() {
        if (!isToneInitialized) {
            await Tone.start();
            isToneInitialized = true;
        }
    }

    // Handle switch clicks
    const switch1 = document.querySelector('#noiseSwitch1');
    const switch2 = document.querySelector('#noiseSwitch2');

    if (switch1) {
        switch1.addEventListener('click', async () => {
            await initTone();
            const isOn = switch1.getAttribute('aria-checked') === 'true';
            
            // Toggle aria-checked state
            switch1.setAttribute('aria-checked', (!isOn).toString());
            
            // Handle audio and animation
            if (!isOn) {
                audioConfig.track1.start();
                // Start rotation animation on the SVG path
                const path = switch1.querySelector('svg path');
                if (path) {
                    gsap.to(path, {
                        rotation: "+=360",
                        duration: 4,
                        repeat: -1,
                        ease: "none",
                        transformOrigin: "center center"
                    });
                }
            } else {
                audioConfig.track1.stop();
                // Stop and reset rotation
                const path = switch1.querySelector('svg path');
                if (path) {
                    gsap.killTweensOf(path);
                    gsap.set(path, { rotation: 0 });
                }
            }
        });
    }

    if (switch2) {
        switch2.addEventListener('click', async () => {
            await initTone();
            const isOn = switch2.getAttribute('aria-checked') === 'true';
            
            // Toggle aria-checked state
            switch2.setAttribute('aria-checked', (!isOn).toString());
            
            // Handle audio and animation
            if (!isOn) {
                audioConfig.track2.start();
                // Start rotation animation on the SVG path
                const path = switch2.querySelector('svg path');
                if (path) {
                    gsap.to(path, {
                        rotation: "+=360",
                        duration: 4,
                        repeat: -1,
                        ease: "none",
                        transformOrigin: "center center"
                    });
                }
            } else {
                audioConfig.track2.stop();
                // Stop and reset rotation
                const path = switch2.querySelector('svg path');
                if (path) {
                    gsap.killTweensOf(path);
                    gsap.set(path, { rotation: 0 });
                }
            }
        });
    }

    // Add dialog functionality
    const dialog = document.getElementById('dialog');
    const openDialog = document.getElementById('openDialog');
    const closeDialog = document.getElementById('closeDialog');

    if (dialog && openDialog && closeDialog) {
        const overlay = document.getElementById('dialog-overlay');
        
        // Function to handle dialog dismissal
        const dismissDialog = () => {
            gsap.to(dialog, {
                y: dialog.offsetHeight,
                duration: 0.3,
                ease: "power2.in",
                onComplete: () => {
                    dialog.setAttribute('aria-expanded', 'false');
                    dialog.setAttribute('inert', ''); // Add inert when hidden
                }
            });
            if (overlay) {
                gsap.to(overlay, {
                    opacity: 0,
                    pointerEvents: 'none',
                    duration: 0.3,
                    ease: "power2.in"
                });
            }
        };

        // Initialize Draggable
        Draggable.create(dialog, {
            type: "y",
            inertia: true,
            bounds: {
                minY: 0,
                maxY: window.innerHeight
            },
            onDrag: function() {
                if (this.y < 0) {
                    this.y = 0;
                }
                
                if (overlay) {
                    
                    const opacity = 1 - (this.y / dialog.offsetHeight);
                    gsap.set(overlay, { opacity: Math.max(0, opacity) });
                }
            },
            onDragEnd: function() {
                const dragPercentage = (this.y / dialog.offsetHeight) * 100;
                
                if (dragPercentage > 15) {
                    dismissDialog();
                } else {
                    gsap.to(dialog, {
                        y: 0,
                        duration: 0.2,
                        ease: "power2.out"
                    });
                    if (overlay) {
                        gsap.to(overlay, {
                            opacity: 1,
                            pointerEvents: 'auto',
                            duration: 0.2,
                            ease: "power2.out"
                        });
                    }
                }
            }
        });

        // Open dialog handler
        openDialog.addEventListener('click', () => {
            dialog.setAttribute('aria-expanded', 'true');
            dialog.removeAttribute('inert'); // Remove inert when visible
            if (overlay) {
                gsap.fromTo(overlay, 
                    { opacity: 0, pointerEvents: 'none' },
                    { 
                        opacity: 1, 
                        pointerEvents: 'auto',
                        duration: 0.25,
                        ease: "power2.out"
                    }
                );
            }
            gsap.fromTo(dialog, 
                { y: dialog.offsetHeight },
                { 
                    y: 0, 
                    duration: 0.3,
                    ease: "power2.out"
                }
            );
        });

        // Close dialog handler
        closeDialog.addEventListener('click', dismissDialog);

        // Add overlay click handler
        if (overlay) {
            overlay.addEventListener('click', dismissDialog);
        }
    }

    // Add click handlers for mode buttons
    const modeButtons = document.querySelectorAll('.toggle-group button');
    modeButtons.forEach(button => {
        button.addEventListener('click', () => {
            // Small delay to ensure body classes have updated
            setTimeout(updateMetaThemeColors, 100);
        });
    });
});